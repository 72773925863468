import React, { useState } from 'react';
import '../../styles/faq/faq.scss';

const faqData = [
  {
    question: 'What are the steps to apply?',
    answer: "Fill out our interest form by clicking any of the apply links under the houses or the link at the top. We’ll contact you within 1 day and you can let us know which house or room looks best to you.  We’ll need proof of identity (passport or drivers license) and proof of internship (screenshot of offer) emailed to us at info@intern.house. We can then send you a lease to review and sign."
  },
  {
    question: 'What are the steps after I sign my lease?   ',
    answer: "You’ll get an email from Doorloop.com to activate your account to be able to pay your rent and log maintenance requests. We’ll add you to a GroupMe with the other tenants in the house. The tenants that are moving out will also be in the chat if you want to ask them any questions! We’ll text and email you a house guide with the wifi password and a guide to food and experiences nearby! We’ll text and email you your front door code and private room code the day before your lease starts."
  },
  {
    question:"How fully furnished are the houses?",
    answer: "The houses are fully furnished in every room including small things like pots, pans, mixing bowls, tupperware, plates, utensils, and more. There are washers and dryers, fridges, stoves, ovens, microwaves, and smartTVs in every house as well. Plus unique amenities like PS5s or garage gyms at some houses. Very similar to an Airbnb. All you need to bring is queen or king sheets (check the 3D tour of your room to see the size of the bed) and bathroom towels for sanitary reasons. The 3D tours are also entirely accurate to what you’ll have when you move in so you can always reference those."
  },
  {
    question:"Are there any leasing or application fees?",
    answer: "Nope, we don’t believe in charging any unnecessary fees."
  },
  {
    question:"When are my first payments due after I sign a lease?",
    answer: "All of our founders work at tech companies full time alongside running Intern.house and we remember being interns. We know you may not have your housing stipend until you move in. So unlike most apartments or housing we don’t require any payments until you move in."
  },
  {
    question:"What if I move in halfway through the month or move out halfway through the month?",
    answer: "We will prorate your rent and only charge you $425 for example if you move in on the 15th of January to a room that’s $850/month in rent. We will also prorate move out dates except for the fall semester, fall semester leases can start on any day but are required to go through the end of December."
  },
  {
    question:"Are utilities included?",
    answer: "We include a monthly maid for all common spaces and bathrooms, lawn mowing, pest control, and trash and recycling pickup at no additional charge to you. Electric, water, gas, and high speed internet (500 mb/s) are an additional charge monthly based on usage. We will bill you through your tenant portal, you won’t have to set up anything yourself. Estimated charge is $60-90/month for electricity, water, gas, and high speed internet depending on the house and time of year."
  },
  {
    question:"How much parking is there at the houses?",
    answer: "There is unlimited street parking at all houses along with 2 garage door openers for garage spaces and of course 2 driveway spaces. We do not charge any additional fees for parking."
  },
  {
    question:"Is there carpooling? What do I do if I don’t have a car?",
    answer: "70% of the interns bring cars so if you don’t have one it’s very normal to carpool together. In fact Tesla for example incentives it and pays out bonuses and free food for carpooling to reduce congestion. All of the houses are in the closest neighborhood to their respective company’s office so a 5-10 minute Uber is easy and cheap too. Lastly, many full-times live in these neighborhoods as well so it’s easy to get a ride with a coworker."
  },
  {
    question:"Will all of the other tenants at my house be interns at my company?",
    answer: "Yes. If you are looking at houses under the filter for your company (e.g. Tesla or AMD) then the interns at that house will only be interns from that company."
  },
  {
    question:"Are there male-only, female-only and co-ed houses?",
    answer: "Yes, we have houses available that will only have female intern roommates, male, or co-ed. Just let us know your preference when you fill out the interest form!"
  }
];

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleFAQ = index => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div id='faq-layout'>
        <h1 id='faq-title'>Frequently Asked Questions</h1>
        <div className='faq-container'>
          {faqData.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div
                className='faq-question'
                onClick={() => toggleFAQ(index)}
              >
                <p className='faq-text'>{faq.question}</p>
                <span className='faq-toggle'>{activeIndex === index ? '−' : '+'}</span>
              </div>
              <div
                className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
              >
                {faq.answer}
              </div>
              {index !== faqData.length - 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default FAQ;
  