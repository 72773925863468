import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import '../../styles/availability/availability.scss';

Modal.setAppElement('#root');

// Import images
function importAll(r) {
  return r.keys().map((item, index) => {
    return { fileName: item.replace('./', '').replace(/\.(png|jpe?g|svg|webp)$/, ''), src: r(item) };
  });
}


const unitImages = importAll(require.context('../../assets/availability', false, /\.(png|jpe?g|svg|webp)$/));

const AvailabilityCard = ({ unit }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = (event) => {
    event.stopPropagation();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [modalIsOpen]);

  const getImageSrc = (picture) => {
    if (picture.includes('https://')) {
      return picture;
    }

    const image = unitImages.find(img => img.fileName === picture);
    return image ? image.src : 'https://via.placeholder.com/150';
  };

  return (
    <div className="unit-card" onClick={openModal}>
      <div className="image-container"><img src={getImageSrc(unit.picture)} alt={unit.unit_name} /></div>
      <h3>{unit.property_name}</h3>
      <p className='list-item'>Unit: {unit.unit_name}</p>
      <p className='list-item'>Rent: ${unit.market_rent}</p>
      <p className='list-item'>Bath: {unit.baths === "1" ? 'Private' : 'Shared'}</p>
      <p className='list-item'>Available: {new Date(unit.earliest_vacancy).toLocaleDateString()}</p>
      <div className="modal-buttons">
        <motion.a
          id="title"
          activeClass="active"
          offset={-115}
          spy={true}
          smooth={true}
          duration={500}
          target="_blank"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          whileInView={{ opacity: 1 }}>
          <button onClick={() => window.open(unit.pics_url)} className="tour-button">3D Tour</button>
        </motion.a>
        <motion.a
          id="title"
          activeClass="active"
          offset={-115}
          spy={true}
          smooth={true}
          duration={500}
          target="_blank"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          whileInView={{ opacity: 1 }}>
          <button onClick={() => window.open(unit.interest_form)} className="interest-button">Apply</button>
        </motion.a>
        {/* <button onClick={() => window.open(unit.application_link)} className="apply-button">Apply</button> */}
      </div>
    </div>
  );
};

export default AvailabilityCard;