import React from 'react';
import Hero from '../hero/Hero';
import Details from '../details/Details';
import Faq from '../faq/Faq';
import Footer from '../footer/Footer';
import AboutUs from '../about/AboutUs';
import Testimonials from '../testimonials/Testimonials';
// import CarouselSection from '../carousel/CarouselSection';
import AvailabilitySection from '../availability/AvailabilitySection';

const HomePage = () => {
    return (
        <div style={{position: 'relative'}}>
            <Hero />
            <Details />
            <AvailabilitySection />
            {/* <CarouselSection /> */}
            <Testimonials />
            <Faq />
            <AboutUs />
            <Footer />
        </div>
    );
}


export default HomePage;
